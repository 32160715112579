<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-8">Kullanıcı Rolleri</div>
            <div class="col-4 text-right">
              <CButton color="success" square
                       size="sm"
                       @click="openModalEvent('create','roleForm', null, 'Yeni Rol Ekle')"
              >
                Rol Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="roles"
            :fields="fields"
            column-filter
            :items-per-page="10"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update','roleForm', lastRole, 'Rol Düzenle')"
            v-on:refresh="getAllRoles"
            :loading="loading"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('update','roleForm', item, 'Rol Düzenle')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('page','rolePageForm', item, 'Rol Sayfa İlişkisi'), getRoleMenus(item)">
                    Rol-Sayfa İlişkisi
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('destroy','confirm', item, 'Rolü Sil', item.name+' isimli rolü silmek istediğinize emin misiniz?'),getRoleMenus(item)">
                    Rolü Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="handleSubmit"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="handleSubmit"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />

  </CRow>
</template>
<script>
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'

export default {
  name: 'Roles',
  components: {
     FormModal, ConfirmModal
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'admin',
      form: 'roleForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      action: '',
      lastRole: {},
      selectedPages: [],
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id'},
        {key: 'name', label: 'Rol Adı'},
        {key: 'securityLevel', label: 'Güvenlik Seviyesi'},

       
      ],
      activePage: 1,
    }
  },
  computed:{
    roles: function() {
      return this.$store.getters.allRoles
    },
    roleSecurityLevels: function () {
      let data = [];
      this.$store.getters.roleSecurityLevels.map(rsl => {
        data.push({value: rsl.name, label: rsl.name, id: rsl.id})
      })
      return data
    },
    loading: function() {
      return this.$store.getters.userLoading || this.$store.getters.adminLoading
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    rowClicked(item, index) {
      this.lastRole = item;
    },
    getAllRoles() {
      this.$store.dispatch('allRole_list')
    },
    getRoleMenus(item) {
      this.$store.dispatch('roleMenus_list', {roleId: item.id})
    },
    async handleSubmit(role, action) {
      if(action == 'page'){
        let ids = []
        let roleSecurityLevelIds = []

        role.map( r => {
          ids.push(r.id)
          roleSecurityLevelIds.push(this.roleSecurityLevels.find(rsl => rsl.value == r.securityLevel).id)
        })
        
        let data = { 
          roleId: this.modalProps.id, 
          pageIds:ids.join(','),
          roleSecurityLevelIds:roleSecurityLevelIds.join(',')
        }

        await this.$store.dispatch('rolePage_action', data)
      }else{
        role.action_type = action
        await this.$store.dispatch('role_action', role)
      }
      
      if(this.$store.getters.adminStatus.success) {
        this.getAllRoles();
        this.openModalEvent()
      }
    },
    
  },
  created() {
    this.getAllRoles();
    this.$store.dispatch('roleSecurityLevel_list')
  }
}
</script>
